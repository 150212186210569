import axios from "axios";

import Actions from "modules/actions";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      actions: new Actions(),
      userInfo: {
        id: null,
        email: "",
      },
      loading: false,
      show1: false,
      show2: false,
      show3: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      btnText: "Submit",
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
        isMinimum: (val) =>
          val.length >= 9 || "Password should be atleast 9 characters.",
        isNonNumeric: (val) =>
          isNaN(val) || "Password should not be numbers alone.",
        isSameWithPassword: (val) =>
          val === this.newPassword ||
          "Confirm password must be the same with new password.",
      },
      error: {
        switch: false,
        msg: "",
      },
    };
  },
  methods: {
    async changePassword() {
      try {
        this.btnText = "Please wait...";

        const validate = await this.$refs.changePasswordForm.validateAsync();

        if (!validate) return;

        const result = await axios({
          method: "post",
          url: "/auth/login",
          data: {
            username: this.userInfo.email,
            pwd: this.currentPassword,
          },
        });
        if (result.status === 200) {
          await this.actions.updateAccountDetails({
            accountId: {
              type: "UUID!",
              value: this.userInfo.id,
              unincludeToFields: true,
            },
            queries: {
              updatePassword: {
                newPassword: {
                  type: "String!",
                  value: this.newPassword,
                },
                confirmPassword: {
                  type: "String!",
                  value: this.confirmPassword,
                },
              },
            },
          });
          await axios.post("/auth/logout");
          this.showNotifyMessage({
            message: "Password successfully updated. Please re-login",
            type: "success",
          });
          this.$router.push("/login");
        } else {
          this.showNotifyMessage({
            message: "Current Password is incorrect, please try again.",
            type: "danger",
          });
        }
      } catch (error) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.btnText = "Submit";
      }
    },
  },
  async mounted() {
    try {
      const getUser = this.actions.getUser();

      if (getUser) {
        this.userInfo.id = getUser.uuid;
        this.userInfo.email = getUser.email;
      }
    } catch (err) {
      this.showNotifyMessage({
        message: "Unable to fetch data.",
        type: "danger",
      });
    }
  },
};
